@import '~base-imports';

[data-theme] {
    background-color: $var-bg-color;
    color: $var-text-color;
}

[data-theme='bg-primary'] {
    --bg-color: #{$theme-bg-primary--bg};
    --text-color: #{$theme-bg-primary--text};
    --link-color: #{$theme-bg-primary--link};
    --link-hover-color: #{$theme-bg-primary--link-hover};
}

[data-theme='bg-primary-dark'] {
    --bg-color: #{$primary-dark};
    --text-color: #{$base-1000};
    --link-color: #{$base-1000};
    --link-hover-color: #{$primary-darker};
}

[data-theme='bg-primary-darker'] {
    --bg-color: #{$primary-darker};
    --text-color: #{$base-1000};
    --link-color: #{$primary};
    --link-hover-color: #{$base-1000};
}

[data-theme='bg-gray'] {
    --bg-color: #{$base-400};
    --text-color: #{$base-000};
    --link-color: #{$theme-bg-gray--link};
    --link-hover-color: #{$theme-bg-gray--link-hover};
}

[data-theme='bg-cold'] {
    --bg-color: #{$primary-darker-opacity};
    --text-color: #{$base-000};
    --link-color: #{$theme-bg-gray--link};
    --link-hover-color: #{$theme-bg-gray--link-hover};
}

[data-theme='bg-gray-primary-darker'] {
    --bg-color: #{$base-400};
    --text-color: #{$primary-darker};
}

[data-theme='bg-dark'] {
    --bg-color: #{$base-000};
    --text-color: #{$base-1000};
    --link-color: #{$base-1000};
    --link-hover-color: #{$base-1000};
}
