@import '~base-imports';

.body {
    padding: var(--s-xl);

    .icon {
        path {
            fill: $support-success;
        }
    }

    .error {
        background-color: $support-error;
        border-radius: 50%;

        path {
            fill: #fff;
        }
    }

    .loading {
        animation: spin 2s linear infinite;
    }

    .buttons {
        a,
        button {
            &:not(:last-child) {
                margin-right: 1rem;
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}
