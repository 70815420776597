@import '~base-imports';

$icon-list-size: toRem(28);

.htmlWrapper {
    width: 100%;
    --tooltip-width: 70vw;

    a {
        @include linksStyles;
    }

    em {
        font-style: normal;
        color: $var-link-color;
    }

    ul {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: toRem(20) 0 0;
        padding: 0;

        li {
            margin-bottom: var(--s-xs);
            margin-left: toRem(36);
            min-height: $icon-list-size;
            position: relative;

            &:before {
                content: '';
                -webkit-mask-image: url('data:image/svg+xml; utf8, <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.3333 14.0001C27.3333 21.3639 21.3638 27.3334 14 27.3334C6.63616 27.3334 0.666626 21.3639 0.666626 14.0001C0.666626 6.63628 6.63616 0.666748 14 0.666748C21.3638 0.666748 27.3333 6.63628 27.3333 14.0001ZM21.5948 10.9573C22.1235 10.4447 22.1365 9.60054 21.6238 9.07189C21.1112 8.54324 20.2671 8.53025 19.7384 9.04288L12.4166 16.1428L9.59482 13.4065C9.06617 12.8939 8.22205 12.9069 7.70943 13.4355C7.1968 13.9642 7.20978 14.8083 7.73843 15.3209L11.4884 18.9573C12.0056 19.4588 12.8276 19.4588 13.3448 18.9573L21.5948 10.9573Z" fill="currentColor"/></svg>');
                mask-image: url('data:image/svg+xml; utf8, <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.3333 14.0001C27.3333 21.3639 21.3638 27.3334 14 27.3334C6.63616 27.3334 0.666626 21.3639 0.666626 14.0001C0.666626 6.63628 6.63616 0.666748 14 0.666748C21.3638 0.666748 27.3333 6.63628 27.3333 14.0001ZM21.5948 10.9573C22.1235 10.4447 22.1365 9.60054 21.6238 9.07189C21.1112 8.54324 20.2671 8.53025 19.7384 9.04288L12.4166 16.1428L9.59482 13.4065C9.06617 12.8939 8.22205 12.9069 7.70943 13.4355C7.1968 13.9642 7.20978 14.8083 7.73843 15.3209L11.4884 18.9573C12.0056 19.4588 12.8276 19.4588 13.3448 18.9573L21.5948 10.9573Z" fill="currentColor"/></svg>');
                background-color: $primary-dark;
                display: block;
                width: $icon-list-size;
                height: $icon-list-size;
                position: absolute;
                left: toRem(-36);
                top: 0;
            }
        }
    }
    a {
        border-bottom: 1px dashed $base-200;
        color: currentColor;
        position: relative;

        &:after,
        &:before {
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 250ms ease-in;
        }

        &:after {
            @include font-styles('b200');
            background: $base-000;
            bottom: calc(100% + toRem(16));
            color: $base-1000;
            content: attr(data-title);
            left: toRem(-8);
            max-width: 40vh;
            overflow: hidden;
            padding: toRem(24);
            position: absolute;
            width: var(--tooltip-width);
            z-index: 100;
            pointer-events: none;
        }

        &:before {
            border-color: #000 transparent transparent transparent;
            border-style: solid;
            border-width: 9px 8px 0 8px;
            bottom: calc(100% + toRem(7));
            content: '';
            left: 0;
            position: absolute;
        }

        &:hover {
            &:after,
            &:before {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .htmlWrapper {
        --tooltip-width: #{toRem(343)};
    }
}
