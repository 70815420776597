@import '~base-imports';

.accordion {
    margin-left: -$grid-margin;
    margin-right: -$grid-margin;

    a {
        transition: $links-transition;
    }
}

.item {
    border-radius: 4px;

    &:not(:last-of-type) {
        margin-bottom: toRem(8);
    }

    .title {
        padding: var(--s-sm) toRem(32) var(--s-sm) 0;

        &::after {
            transition: all ease-in-out 0.2s;
            transform: none;
            top: var(--s-sm) !important;
            margin-top: toRem(4) !important;
        }
    }
}

.openItem {
    .title {
        &::after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z' fill='%23111111'/%3E%3C/svg%3E");
        }
    }
}

.body {
    border-top: 1px solid $base-300;
}

@include media-breakpoint-up(lg) {
    .accordion {
        margin-left: auto;
        margin-right: auto;
    }
}
