@import '~base-imports';

.row {
    row-gap: toRem(32);

    @include media-breakpoint-up(md) {
        row-gap: toRem(24);
    }

    @include media-breakpoint-up(xxl) {
        row-gap: toRem(40);
    }
}

.cta {
    text-align: center;
}
