@import '~base-imports';

.wrapperContent {
    position: relative;
    background-color: $primary-dark;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: flex-end;
        overflow: hidden;
    }
}

.wrapperImage {
    position: relative;
    overflow: hidden;
    height: toRem(280);

    @include media-breakpoint-up(md) {
        left: 0;
        position: absolute;
        width: 50%;
        height: 100%;
    }
}

.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.cover {
    object-fit: cover;
}

@include media-breakpoint-up(md) {
    .wrapperInfo {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -50%;
            width: 50%;
            height: 100%;
            background: linear-gradient(270deg,
                    $primary-dark 0%,
                    rgba($primary-dark, 0.83) 22.14%,
                    rgba($primary-dark, 0) 65.79%);
        }
    }
}
