@import '~base-imports';

.unmount-form {
    min-height: toRem(504);
    @include media-breakpoint-up(xxl) {
        min-height: toRem(508);
    }
}

/* New styles for customs forms */

.lb-input {
    width: 100%;
    height: 58px;
    border-radius: 30px;
    border: 1px solid #e0e0e0;
    padding-left: 20px;
}

.lb-input::placeholder {
    color: #c4c4c4;
}

.lb-input-textarea {
    width: 100%;
    height: 7.5rem;
    border-radius: 30px;
    border: 1px solid #e0e0e0;
    padding: 16px 20px;
    margin-top: 20px;
}

.lb-input-textarea::placeholder {
    color: #c4c4c4;
}

.lb-input-textarea-error-border {
    width: 100%;
    height: 7.5rem;
    border-radius: 30px;
    border: 1px solid #c63532;
    padding: 16px 20px;
    margin-top: 20px;
}

.lb-input-error-border {
    width: 100%;
    height: 58px;
    border-radius: 30px;
    border: 1px solid #c63532;
    padding-left: 20px;
}

.lb-input-error {
    width: 100%;
    font-size: 13px;
    padding-left: 20px;
    color: #c63532;
    letter-spacing: 0.8px;
}

.lb-input-valid {
    width: 100%;
    font-size: 13px;
    padding-left: 20px;
    color: #407d40;
    letter-spacing: 0.8px;
}

.lb-input-select {
    width: 75%;
    height: 58px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #e0e0e0;
    padding-left: 20px;
}

.lb-input-select-error {
    width: 75%;
    height: 58px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border: 1px solid #c63532;
    padding-left: 20px;
}

.lb-input-select-box {
    width: 25%;
    height: 58px;
    display: flex;
    position: relative;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 1px solid #e0e0e0;
    border-right: 0;
    padding-left: 0;
    cursor: pointer;
}

.lb-input-select-box-error {
    width: 25%;
    height: 58px;
    display: flex;
    position: relative;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border: 1px solid #c63532;
    border-right: 0;
    padding-left: 0;
    cursor: pointer;
}

.lb-input-select-options {
    position: absolute;
    width: 100%;
    max-height: 200px;
    top: 58px;
    left: 0;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 30px;
    z-index: 9999;
    overflow: auto;
    padding: 5px 10px;
}

.lb-input-select-options-row {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #797979;
    cursor: pointer;
}

.lb-input-select-options-input {
    width: 100%;
    height: 40px;
    border: none;
}

.lb-input-select-options-input:focus-visible {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
}

.lb-input-select-options-search {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.lb-input-select-container-flags {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    color: #797979;
}

.lb-input-select-container-flags-icon {
    width: 17px;
    height: 17px;
    background: url('./chevron-down.svg');
}

.lb-input-custom-stripe {
    display: block;
    width: 100%;
    height: 58px;
    padding-top: 20px;
    border-radius: 30px;
    border: 1px solid #e0e0e0;
    padding-left: 20px;
}

.lb-input-custom-stripe-error {
    display: block;
    width: 100%;
    height: 58px;
    padding-top: 20px;
    border-radius: 30px;
    border: 1px solid #c63532;
    padding-left: 20px;
}

.lb-form-row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
}

.lb-form-row-submit {
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lb-mt-100 {
    margin-top: 100px;
}

.lb-form-row-terms-container {
    width: 100%;
    margin-top: 30px;
    text-align: center;
}

.lb-form-record {
    width: 49%;
    height: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.lb-form-label-editor {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 0;
    padding-left: 1.25rem;
    text-align: left;
}

.lb-form-payment-titles {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding-top: 0;
    padding-left: 1.25rem;
    margin-bottom: 0.5rem;
}

.lb-form-btn-apply {
    position: absolute;
    top: 14px;
    right: 29px;
    color: #285f7a;
    background-color: transparent;
    border: none;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
}

.lb-form-btn-apply:hover {
    color: #019494;
}

.lb-form-btn {
    position: relative;
    border-radius: 40px;
    padding: 20px 32px;
    color: #285f7a;
    background-color: #92e735;
    border: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.lb-form-btn:hover {
    color: #fff;
    background-color: #285f7a;
}

.lb-form-btn:disabled, .lb-form-btn[disabled=disabled], .lb-form-btn[disabled=disabled]:hover {
    border-radius: 40px;
    padding: 20px 32px;
    color: #797979;
    background-color: #e0e0e0;
    border: none;
    cursor: no-drop;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.lb-form-btn-dwnld {
    width: 100%;
    margin-top: 20px;
    border-radius: 40px;
    padding: 20px 32px;
    color: #285f7a;
    background-color: #92e735;
    border: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.lb-form-btn-dwnld:hover {
    color: #fff;
    background-color: #285f7a;
}

.lb-form-btn-dwnld:disabled, .lb-form-btn-dwnld[disabled=disabled], .lb-form-btn-dwnld[disabled=disabled]:hover {
    width: 100%;
    border-radius: 40px;
    padding: 20px 32px;
    color: #797979;
    background-color: #e0e0e0;
    border: none;
    cursor: no-drop;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.lb-form-btn-s{
    font-size: 20px;
    font-weight: 700;
}

.lb-form-btn-s-error{
    font-size: 14px;
    font-weight: 300;
    color:#c63532;
    margin-bottom: 5px;
}

.lb-form-btn-subs {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    position: relative;
    padding: 10px 20px 10px 56px;
    height: 88px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.lb-form-btn-subs-tag {
    position: absolute;
    right: 0;
    top: 0;
    border-bottom-left-radius: 5px;
    background-color: #019494;
}

.lb-form-btn-subs-tag-text {
    font-size: 16px;
    color: #fff;
    padding: 4px 12px;
}

.lb-form-btn-subs-radius {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #737373;
    left: 20px;
}

.lb-form-btn-subs-radius-active {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 4px solid #444444;
    left: 20px;
}

.lb-form-btn-subs-text {
    display: flex;
    flex-direction: column;
}

.lb-form-btn-subs-text-title {
    font-size: 21px;
    line-height: 32px;
    font-weight: bold;
}

.lb-form-btn-subs-text-price {
    font-weight: bold;
}

.lb-form-btn-subs-text-save {
    font-size: 13px;
    line-height: 20px;
    color: #c4c4c4;
}

.lb-form-textCard {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 40px;
}

.lb-form-textCard-text {
    font-size: 16px;
    font-weight: bold;
    margin-right: 25px;
}

.lb-form-textCard-img {
    height: 25px;
    width: auto;
}

.lb-form-title {
    font-weight: bolder;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 0;
    margin-bottom: 40px;
}

.lb-form-title-step {
    color: #019494;
}

.lb-input-select-states-text {
    font-size: 16px;
    color: #797979;
    padding: 14px 0;
    cursor: pointer;
}

.lb-form-messagge-limted-time {
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.lb-form-messagge-limted-time-title {
    background-color: #ff495c;
    font-size: 16px;
    line-height: 24px;
    padding: 4px 0;
    color: #fff;
    text-align: center;
}

.lb-form-messagge-limted-time-table {
    padding: 1.25rem;
}

.lb-form-messagge-limted-time-table-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.lb-form-messagge-limted-time-table-text-underline {
    font-size: 14px;
    color: #111;
    opacity: 0.6;
    text-decoration: line-through;
    margin-bottom: 0.25rem;
}

.lb-form-messagge-limted-time-table-text {
    font-size: 21px;
    color: #111;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.lb-form-messagge-limted-time-table-one {
    font-size: 14px;
    color: #111;
    line-height: 20px;
    margin-bottom: 0;
}

.lb-form-messagge-limted-time-table-space {
    width: 100%;
    height: 1px;
    margin: 32px 0;
    background-color: #e0e0e0;
}

.lb-form-review {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    background-color: #92e735;
    border-radius: 4px;
    padding: 1.5rem;
}

.lb-form-review-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    padding: 1.5rem;
}

.lb-form-review-icons-container {
    display: flex;
    flex-direction: row;
}

.lb-form-review-icons {
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-top: 6px;
    background: url('./star.svg') no-repeat;
}

.lb-form-review-down {
    padding-top: 8px;
}

.lb-form-review-comment {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
}

.lb-form-review-member {
    font-size: 14px;
    line-height: 20px;
}

.lb-form-contact-title {
     font-size: 16px;
     font-weight: 600;
}

.lb-form-contact-row-legals {
    display: flex;
    margin-top: 20px;
}

.lb-form-contact-row-legals-check {
    width: 16px;
    height: 16px;
    border: 2px solid #c4c4c4;
    border-radius: 3px;
    margin-right: 8px;
    background-color: #fff;
    transition: background-color .2s ease;
}

.lb-form-contact-row-legals-check-checked {
    width: 16px;
    height: 16px;
    border: 2px solid #92e735;
    border-radius: 3px;
    margin-right: 8px;
    background-color: #92e735;
}

.lb-form-contact-row-legals-check-bg {
    width: 14px;
    height: 14px;
    margin-top: -2px;
    margin-left: -2px;
    background: url('./check.svg') no-repeat;
}

.lb-form-contact-row-legals-check-privacity {
    font-size: 18px;
    margin-top: -4px;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */

    .lb-form-btn {
        border-radius: 40px;
        padding: 20px 24px;
        font-size: 17px;
        color: #285f7a;
        margin-top: 10px;
        background-color: #92e735;
        border: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    
    .lb-form-btn:hover {
        color: #fff;
        background-color: #285f7a;
    }
    
    .lb-form-btn:disabled, .lb-form-btn[disabled=disabled], .lb-form-btn[disabled=disabled]:hover {
        border-radius: 40px;
        padding: 20px 24px;
        color: #797979;
        background-color: #e0e0e0;
        border: none;
        cursor: no-drop;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .lb-form-btn-s{
        font-size: 17px;
        font-weight: 700;
    }
    
    .lb-form-btn-s-error{
        font-size: 14px;
        font-weight: 300;
        color:#c63532;
        margin-bottom: 5px;
    }

    .lb-form-label-editor {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-top: 25px;
        padding-left: 1.25rem;
        margin-bottom: -16px;
    }

    .lb-form-payment-titles {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding-top: 25px;
        padding-left: 1.25rem;
        margin-bottom: -16px;
    }

    .lb-input {
        width: 100%;
        height: 58px;
        border-radius: 30px;
        border: 1px solid #e0e0e0;
        padding-left: 20px;
        margin-top: 20px;
    }

    .lb-input-select-box {
        width: 25%;
        height: 58px;
        display: flex;
        position: relative;
        align-items: center;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border: 1px solid #e0e0e0;
        border-right: 0;
        padding-left: 0;
        margin-top: 20px;
        cursor: pointer;
    }

    .lb-form-record {
        width: 100%;
        height: auto;
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .lb-form-row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0px;
    }

    .lb-input-select {
        width: 75%;
        height: 58px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border: 1px solid #e0e0e0;
        padding-left: 20px;
        margin-top: 20px;
    }

    .lb-input-custom-stripe {
        display: block;
        width: 100%;
        height: 58px;
        padding-top: 20px;
        border-radius: 30px;
        border: 1px solid #e0e0e0;
        padding-left: 20px;
        margin-top: 20px;
    }

    .lb-input-custom-stripe-error {
        display: block;
        width: 100%;
        height: 58px;
        padding-top: 20px;
        border-radius: 30px;
        border: 1px solid #c63532;
        padding-left: 20px;
        margin-top: 20px;
    }

    .lb-form-btn-apply {
        position: absolute;
        top: 34px;
        right: 29px;
        color: #285f7a;
        background-color: transparent;
        border: none;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
    }

    .lb-form-btn-subs {
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        position: relative;
        padding: 10px 20px 10px 56px;
        height: 88px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .lb-form-btn-subs-tag-text {
        font-size: 14px;
        color: #fff;
        padding: 4px 7px;
    }

    .lb-form-title {
        font-weight: bolder;
        font-size: 25px;
        line-height: 40px;
        letter-spacing: 0;
        margin-bottom: 10px;
    }

    .lb-form-review {
        width: 100%;
        display: none;
        gap: 0.5rem;
        background-color: #92e735;
        border-radius: 4px;
        padding: 1.5rem;
    }

}

.disabled {
    color: $base-300;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}


/* End new styles for customs forms */

// .base-form {
//     :global(.af-form) {
//         --input-border-radius: #{$input-border-radius};
//         --invalid-field-background-color: #{$input-bg};
//         --invalid-field-border-color: #{$form-feedback-invalid-color};
//         --message-fail-font-color: #{$form-feedback-invalid-color};
//         --field-error-font-color: #{$form-feedback-invalid-color};
//         --legal-font-size: #{$input-font-size};
//         --dropdown-background-color: #fff;

//         :global(.af-field) {
//             margin-bottom: var(--s-md);
//             textarea {
//                 min-height: toRem(116);
//             }
//         }

//         :global(.af-field-error p) {
//             margin: toRem(8) 0 0 toRem(20);
//         }

//         :global(.af-button) {
//             width: fit-content;
//             margin: var(--s-md) 0 0 auto;
//             button {
//                 @include btn-type('primaryLarge');
//                 @include transition($button-transition);
//             }
//         }

//         :global(.af-dropdown),
//         :global(.af-dropdown-text) {
//             padding: $input-padding-y $input-padding-x;
//             padding-right: toRem(52);
//         }

//         :global(.af-tel-countries) {
//             padding: 0;
//         }

//         :global(.af-dropdown-search-options),
//         :global(.af-tel-option-list) {
//             margin-top: toRem(8);
//             div {
//                 color: $input-placeholder-color;
//                 &:hover {
//                     background-color: $input-bg;
//                     color: $input-color;
//                 }
//             }
//         }

//         :global(.af-dropdown-icon) {
//             width: toRem(24);
//             right: 20px;
//         }

//         :global(.af-legal-required) {
//             margin: 0;
//             &::before {
//                 min-width: toRem(16);
//                 width: toRem(16);
//                 height: toRem(16);
//                 border: 2px solid $base-000;
//                 border-radius: 2px;
//                 background-color: $base-400;
//             }
//         }

//         :global(.af-legal) {
//             label {
//                 display: block;
                
//                 &::before,
//                 &::after {
//                     top: 1px;
//                     position: absolute;
//                 }

//                 &::after {
//                     top: 5px;
//                 }
//             }

//             p {
//                 padding-left: 1.5rem;
//                 margin-bottom: var(--s-xxs);
//             }


//             input:checked + label:before {
//                 background-color: $base-000;
//             }
//         }

//         :global(.af-branding) {
//             display: none;
//         }

//         :global(.af-step-messages) {
//             :global(.af-step-success) {
//                 position: relative;
//                 margin: var(--s-md) 0 0 0;
//                 padding-left: toRem(28);

//                 &::before {
//                     content: '';
//                     position: absolute;
//                     height: toRem(14);
//                     width: toRem(20);
//                     left: 0;
//                     top: toRem(5);
//                     background-position: center;
//                     background-repeat: no-repeat;
//                     background-size: cover;
//                     background-image: url("data:image/svg+xml,%3Csvg width='24' height='16' viewBox='0 0 24 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23.3793 3.24758C24.2069 2.48625 24.2069 1.29665 23.3793 0.535316C22.5517 -0.178439 21.2586 -0.178439 20.4828 0.535316L8.68966 11.3844L3.51724 6.62602C2.68966 5.91227 1.39655 5.91227 0.62069 6.62602C-0.206897 7.38736 -0.206897 8.57695 0.62069 9.33829L7.24138 15.429C7.60345 15.8097 8.17241 16 8.68966 16C9.2069 16 9.72414 15.8097 10.1379 15.429L23.3793 3.24758Z' fill='%2376C835'/%3E%3C/svg%3E%0A");
//                 }

//                 &::after {
//                     margin-top: -2px;
//                 }
//             }
//         }

//         :global(.af-tel-dropdown-container) {
//             :global(.af-dropdown-icon),
//             :global(.af-tel-flag-wrapper),
//             :global(.af-tel-dialcode) {
//                 position: static;
//                 flex: 0 0 auto;
//             }

//             :global(.af-dropdown-icon) {
//                 height: 24px;
//                 width: 10px;
//                 -webkit-mask-size: contain;
//                 mask-size: contain;
//                 left: 90px;
//                 top: $input-padding-y / 2;
//                 opacity: 0.5;
//             }
//         }

//         :global(.af-tel-country-button) {
//             padding: var(--input-padding);
//             border-right: solid 1px var(--input-border-color);
//             height: 100%;
//             width: 116px;
//             display: flex;
//             align-items: center;
//             gap: 2px;
//         }

//         :global(.af-tel-dialcode) {
//             font-size: 14px;
//         }

//         :global(.af-tel-international input[type='tel']) {
//             padding-left: 132px;
//         }

//         :global(.af-field-has-error) {
//             :global(.af-field-input) {
//                 input {
//                     color: var(--input-font-color);
//                 }
//             }
//         }
//         :global(.af-tel-country) {
//             padding: $input-padding-y $input-padding-x;

//             :global(.af-tel-country-flag-wrapper),
//             :global(.af-tel-country-dialcode) {
//                 top: $input-padding-y;
//             }
//         }
//     }
// }
