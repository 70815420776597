@import '~base-imports';

.icon {
    color: $primary-dark;
    margin-bottom: toRem(4);
}

.text {
    font-weight: normal;
}

.item {
    border-bottom: 1px solid $base-300;

    &:nth-child(even) {
        border-right: 1px solid $base-300;
    }
}

.link {
    text-decoration: underline;
}

@include media-breakpoint-up(lg) {
    .item {
        border-bottom: 1px solid $base-300;

        &:nth-child(odd) {
            border-right: 1px solid $base-300;
        }

        &:nth-child(4n + 5) {
            border-left: 1px solid $base-300;
        }

        &:nth-child(-n + 4) {
            border-top: 1px solid $base-300;
        }
    }
}

@include media-breakpoint-up(xl) {
    .item {
        padding: var(--s-md) var(--s-xl);
    }

    .icon {
        flex: 0 0 auto;
        height: toRem(40) !important;
        width: toRem(40) !important;
    }
}

@include media-breakpoint-up(xxl) {
    .icon {
        margin-bottom: 0;
        margin-right: var(--s-tiny);
    }
}
