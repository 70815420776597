@import '~base-imports';

.card {
    column-gap: 8px;
    display: grid;
    grid-template-areas:
        'img title'
        'img desc';
    grid-template-columns: 32px auto;
    height: 100%;

    @include media-breakpoint-up(lg) {
        display: block;
        padding: var(--s-xg);
        text-align: center;
    }
}

.image {
    border-radius: 50%;
    display: inline-block;
    grid-area: img;
    height: 32px;
    overflow: hidden;
    width: 32px;

    @include media-breakpoint-up(lg) {
        height: 96px;
        width: 96px;
    }
}

.title {
    grid-area: title;

    @include media-breakpoint-up(lg) {
        margin-bottom: var(--s-md);
    }
}

.text {
    grid-area: desc;
}

.icon {
    width: toRem(24);
    height: toRem(24);
    grid-area: img;
    color: $primary-dark;

    @include media-breakpoint-up(lg) {
        width: toRem(54.4);
        height: toRem(54.4);
    }
}
