@import '~base-imports';

.fill {
    color: $primary;
}
.parent {
    line-height: 0;
}

.hide {
    visibility: hidden;
    opacity: 0;
    line-height: 0;
}
