@import '~base-imports';

.wrapper {
    overflow-x: scroll;
    display: flex;
    flex-wrap: nowrap;
    --outside-space: var(--grid-margin);
    --card-width: #{toRem(263)};

    &:before,
    &:after {
        content: '';
        flex: 0 0 var(--outside-space);
    }

    .item {
        display: grid;
        flex: 0 0 var(--card-width);
    }

    .item + .item {
        margin-left: var(--grid-gutter-width);
    }

    &::-webkit-scrollbar {
        display: none;
    }

    & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        --card-width: #{toRem(263)};
        --outside-space: calc(
            50% - (var(--container-max-width) / 2) + var(--grid-margin)
        );

        &.reverse {
            flex-direction: row-reverse;

            .item + .item {
                margin-left: 0;
                margin-right: var(--grid-gutter-width);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .wrapper {
        --card-width: #{toRem(263)};
    }
}

@include media-breakpoint-up(xxl) {
    .wrapper {
        --card-width: #{toRem(263)};
    }
}
