@import '~base-imports';

.wrapper {
    display: grid;
    gap: toRem(32);
}

.carousel {
    --card-width: #{toRem(296)};
    
    &:before,
    &:after {
        display: none;
    }
}

@include media-breakpoint-up(sm) {
    .carousel {
        --card-width: #{toRem(330)};
    }
}

.controls {
    display: block !important;

    > div {
        padding: 0;
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        gap: toRem(85);
        grid-template-columns: repeat(3, 1fr);
    }

    .item {
        &:not(:first-of-type) {
            &:after {
                -webkit-mask-image: url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="425" height="1024" viewBox="0 0 425 1024"><path d="M40.284 62.005l-32.82-41.354 12.34-9.794 32.82 41.354-12.34 9.794zM171.567 227.421l-65.642-82.708 12.34-9.794 65.642 82.708-12.34 9.794zM302.848 392.836l-65.642-82.708 12.34-9.794 65.642 82.708-12.34 9.794zM397.423 512l-28.934-36.456 12.34-9.794 36.706 46.25-36.706 46.252-12.34-9.794 28.934-36.458zM237.207 713.873l65.642-82.708 12.34 9.794-65.642 82.708-12.34-9.794zM105.925 879.288l65.642-82.708 12.34 9.794-65.642 82.708-12.34-9.794zM7.464 1003.35l32.82-41.354 12.34 9.794-32.82 41.354-12.34-9.794z"></path></svg>');
                mask-image: url('data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="425" height="1024" viewBox="0 0 425 1024"><path d="M40.284 62.005l-32.82-41.354 12.34-9.794 32.82 41.354-12.34 9.794zM171.567 227.421l-65.642-82.708 12.34-9.794 65.642 82.708-12.34 9.794zM302.848 392.836l-65.642-82.708 12.34-9.794 65.642 82.708-12.34 9.794zM397.423 512l-28.934-36.456 12.34-9.794 36.706 46.25-36.706 46.252-12.34-9.794 28.934-36.458zM237.207 713.873l65.642-82.708 12.34 9.794-65.642 82.708-12.34-9.794zM105.925 879.288l65.642-82.708 12.34 9.794-65.642 82.708-12.34-9.794zM7.464 1003.35l32.82-41.354 12.34 9.794-32.82 41.354-12.34-9.794z"></path></svg>');
                -webkit-mask-size: contain;
                mask-size: contain;
                background-color: $primary-dark;
                content: '';
                display: block;
                height: toRem(60);
                left: calc(toRem(-25) - toRem(30));
                position: absolute;
                top: toRem(59);
                width: toRem(25);
            }
        }
    }
}
