@import '~base-imports';

.contactFormContainer {
    margin-left: -$grid-margin;
    margin-right: -$grid-margin;
}

.logo {
    width: toRem(166);
    @include media-breakpoint-up(xxl) {
        width: toRem(213);
    }
}

.contact-form {
    :global(.af-form) {
        --legal-font-color: theme-color('base-1000');

        :global(.af-rich-text-block) {
            @include font-styles('b200');
            color: theme-color('base-1000');
            margin-bottom: var(--s-md);
            font-weight: 600;
        }

        :global(.af-legal) {
            input:checked + label {
                &:before {
                    border-color: $primary;
                    background-color: $primary;
                }
            }
        }

        :global(.af-legal-required) {
            align-items: baseline;

            &::before {
                border: 2px solid $base-300;
                background-color: $base-1000;
                flex: 0 0 auto;
            }
            &::after {
                border-color: $primary-darker;
                top: toRem(10);
            }
        }

        :global(.af-step-messages) {
            :global(.af-step-success) {
                position: relative;
                margin: var(--s-md) 0 0 0;
            }

            :global(.af-step-failure) {
                display: none !important;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .contactFormContainer {
        border-radius: toRem(4);
        margin-left: 0;
        margin-right: 0;
    }

    .contact-form {
        :global(.af-form) {
            :global(.af-step-messages) {
                :global(.af-step-success) {
                    margin: 0;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .contact-form {
        :global(.af-form) {
            :global(.af-legal-required) {
                align-items: center;

                a {
                    transition: $links-transition;
                }
            }
        }
    }
}
