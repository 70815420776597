@import '~base-imports';

@include media-breakpoint-up(lg) {
    .text {
        margin: 0 var(--s-lg) var(--s-none) 0;
    }
}

@include media-breakpoint-up(sm) {
    .cta {
        text-align: end;
    }
}
