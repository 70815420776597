@import 'fonts';
@import 'themes';

[data-reactroot] {
    min-height: calc(100 * var(--vh, 1vh));
    display: flex;
    flex-direction: column;
}

body main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}
