@import '~base-imports';

.text {
    a {
        @include linksStyles;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
            padding-left: toRem(16);
            text-indent: toRem(-16);

            &:before {
                background-color: currentColor;
                border-radius: 50px;
                content: '';
                display: inline-block;
                height: toRem(8);
                margin-right: toRem(8);
                vertical-align: middle;
                width: toRem(8);
            }
        }
    }

    code {
        background-color: $base-400;
        display: inline-block;
        font-family: inherit;
        padding: toRem(4);
    }
}
