@import '~base-imports';
.root {
    @each $breakpoint, $value in $navbar-offset {
        @if ($breakpoint == 'null') {
            --navbar-offset: #{toRem($value)};
        } @else {
            @include media-breakpoint-up($breakpoint) {
                --navbar-offset: #{toRem($value)};
            }
        }
    }
}
.root,
.collapse-content {
    border-bottom: 1px solid $base-350;
}

.collapse {
    z-index: 3;
}

.btnIcon {
    transition: color $header-speed ease-in-out;

    + .btnIcon {
        margin-left: var(--s-tiny);
    }

    &:last-of-type {
        margin-right: var(--s-tiny);
    }
}

.callMeForm {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0 var(--s-sm) toRem(20);
    width: 100%;
    background-color: $base-1000;
    border-bottom: 1px solid $base-350;
    opacity: 0;
    pointer-events: none;
    z-index: 2;

    &,
    > div {
        margin: 0;
    }
}

.callMeForm,
.nav-buttons {
    transition: opacity $header-speed ease-in-out;
}

body[data-first-section-type='hero_section'][data-scroll='none'] {
    .callMeForm {
        input {
            border-color: transparent;
        }
    }
}

body[data-scroll]:not([data-scroll='none']) {
    .callMeForm {
        opacity: 1;
        pointer-events: all;
    }
}

.footer-mobile {
    --link-color: #{$base-000};
    --link-hover-color: #{$base-000};
    margin-bottom: toRem(8);
}

.avatar {
    border-radius: 50%;
    width: toRem(24);
    height: toRem(24);
    overflow: hidden;
    margin: 0 var(--s-tiny);
}

.navigation-list-item {
    &[data-open] {
        .navigation-item {
            justify-content: flex-start;
            gap: toRem(4);
        }
    }
}

// Prevent wrong styles for links that are just anchors
.navigation-item-active:not([href*='#']) {
    border-color: currentColor !important;
}

@include media-breakpoint-up(lg) {
    .nav-buttons {
        pointer-events: none;
        opacity: 0;
        margin-right: var(--s-xxs);

        li + li {
            margin-left: toRem(8) !important;
        }
    }

    .callMeForm {
        order: 1;
        flex: 1;
        top: 50%;
        transform: translateY(-50%);
        right: toRem(68);
        padding: 0;
        background-color: transparent;
        border: none;
        max-width: toRem(280);
        opacity: 1;
        pointer-events: all;
    }

    body[data-scroll]:not([data-scroll='none']) {
        .callMeForm {
            opacity: 0;
            pointer-events: none;
        }

        .nav-buttons {
            opacity: 1;
            pointer-events: all;
        }
    }

    .btnIcon {
        order: 2;
        margin: 0 !important;
        svg {
            width: toRem(24) !important;
            height: toRem(24) !important;
        }
    }

    .avatar {
        order: 2;
        margin: 0;
        width: toRem(32);
        height: toRem(32);
    }

    .nav-main {
        --navbar-translate: #{toRem(-1.5)};
        left: 0 !important;
        transform: translate(
            var(--navbar-offset),
            var(--navbar-translate)
        ) !important;
    }

    .collapse {
        z-index: 1;
    }

    .collapse-content {
        background-color: transparent !important;
        border: none;
    }

    .navigation-list-item {
        &[data-open='true'] {
            .navigation-item svg {
                transform: none;
            }
        }

        &[data-hover='true'] {
            .navigation-item svg {
                transform: rotate(180deg);
            }
        }
    }

    .navigation-item {
        @include linksStyles;

        // Remove hover effect in links with megamenu
        &.withChildren {
            height: 100%;
            border: 0;

            &.navigation-item-active {
                span {
                    border-bottom: 1px solid currentColor;
                }
            }
        }

        &:focus {
            border-color: transparent;
        }
    }

    .root {
        &:before {
            opacity: 0;
            transition-delay: 0;
        }
    }

    body[data-first-section-type='hero_section'] {
        &:not([data-scroll]),
        &[data-scroll='none'] {
            .btnIcon,
            .navigation-item {
                color: $base-1000;
                --link-hover-color: #{$base-1000};
            }

            .root {
                background-color: transparent;
                border-bottom-color: transparent;
                &:before {
                    transition: opacity $header-speed ease-in-out;
                    opacity: 1;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: toRem(172);
                    background-image: linear-gradient(
                        0,
                        rgba(0, 0, 0, 0) 0%,
                        rgba(0, 0, 0, 0.0086472) 6.67%,
                        rgba(0, 0, 0, 0.03551) 13.33%,
                        rgba(0, 0, 0, 0.0816599) 20%,
                        rgba(0, 0, 0, 0.147411) 26.67%,
                        rgba(0, 0, 0, 0.257309) 53.33%,
                        rgba(0, 0, 0, 0.368116) 60%,
                        rgba(0, 0, 0, 0.468225) 66.67%,
                        rgba(0, 0, 0, 0.552589) 73.33%,
                        rgba(0, 0, 0, 0.61834) 80%,
                        rgba(0, 0, 0, 0.66449) 86.67%,
                        rgba(0, 0, 0, 0.691353) 100%
                    );
                }

                img[data-name='black'] {
                    opacity: 0;
                }
                img[data-name='white'] {
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .callMeForm {
        max-width: toRem(304);
    }

    .nav-buttons {
        li + li {
            margin-left: var(--s-xs) !important;
        }
    }
}

@include media-breakpoint-up(xxl) {
    .nav-buttons {
        margin-right: var(--s-xs);
    }

    .callMeForm {
        right: toRem(120);
    }

    .nav-main {
        --navbar-translate: #{toRem(-2.5)};
    }

    .btnIcon {
        svg {
            width: toRem(40) !important;
            height: toRem(40) !important;
        }
    }
}
