@import '~base-imports';

.wrapper {
    margin-bottom: 0;
    max-width: toRem(571);
    width: 100%;
}

.input {
    padding-left: calc(var(--s-sm) + #{$input-padding-x} + var(--s-tiny));
}

.icon {
    color: $base-200;
    left: var(--s-sm);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.dropdown {
    position: absolute;
    top: calc(100% + var(--s-tiny));
    width: 100%;
    background-color: $input-bg;
    border: solid 1px $base-300;
    border-radius: 30px;

    z-index: 1;
}

.link {
    display: block;
    color: $base-000;

    &:hover {
        color: $primary-dark;
    }
}
