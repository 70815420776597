@import '~base-imports';

.wrapper {
    position: absolute;
    height: 100%;
    width: 50%;
    right: 0;
    top: 0;
    overflow: hidden;
}

.wrapperCover {
    overflow: hidden;
    width: 100%;
}

.video {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        object-fit: cover;
    }
}
