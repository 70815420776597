@import '~base-imports';

$menu-image-size: 60;

.wrapperImage {
    border-radius: 50%;
    width: toRem($menu-image-size);
    height: toRem($menu-image-size);
    overflow: hidden;
}

.list {
    padding: 0;
    list-style: none;
}
