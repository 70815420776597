@import '~base-imports';

.heroSection {
    padding-top: var(--s-md) !important;

    &.fullscreen {
        height: calc(100 * var(--vh, 1vh));
    }
}

.heroSecondary {
    &.fullscreen {
        height: calc(100 * var(--vh, 1vh));
    }
}

.heroContainer {
    z-index: 1;
}

.ctaMobile {
    width: 100%;
}

.ctasButtons {
    & + .ctasButtons {
        margin-left: var(--s-sm);
    }
}

.link {
    &:hover,
    &:focus {
        color: $primary;
        text-decoration: underline;
    }
}

@include media-breakpoint-up(lg) {
    .heroSection {
        padding-top: var(--s-xxxl) !important;
        padding-bottom: var(--s-xl) !important;
        max-height: 75vh;

        &:after {
            --gradient: rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0.65) 30%,
                rgba(0, 0, 0, 0) 100%;
            pointer-events: none;
            background: -moz-linear-gradient(left, var(--gradient));
            background: -webkit-linear-gradient(left, var(--gradient));
            background: linear-gradient(to right, var(--gradient));
            bottom: 0;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            width: 100%;
        }

        &.heroSearch {
            &:after {
                --gradient: rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 93.75%;
            }
        }
    }
    // .heroSecondary {
    //     max-height: 75vh;
    // }
}
