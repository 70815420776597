@import '~base-imports';

.table {
    width: 100%;
    table-layout: fixed;
}

.headTable {
    background: $primary-darker;
    color: $base-1000;
    text-align: center;
}

.cell {
    padding: var(--s-sm);
    text-align: center;
    @include media-breakpoint-up(lg) {
        text-align: start;
    }
}
.cellHead {
    padding: var(--s-sm);
    text-align: center;
}

.table,
.cellHead {
    border: 1px solid $base-200;
    border-collapse: collapse;
}

@include media-breakpoint-up(md) {
    .table {
        border: 1px solid $base-200;
        border-collapse: collapse;
    }
}

.contentText {
    display: block;
    @include media-breakpoint-up(lg) {
        display: inline-block;
    }
}

.icon {
    margin: 0 0 var(--s-tiny);
    flex-shrink: 0;
    
    @include media-breakpoint-up(lg) {
        margin: 0 var(--s-xs) 0 0;
    }
}

.sticky {
    position: sticky;
    top: var(--navbar-height);
}

.yes {
    color: $primary-dark;
}

.no {
    color: $support-error;
}
