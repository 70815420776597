@import '~base-imports';

.card {
    position: relative;
    border: 1px solid $base-300;
    border-radius: 4px;
}

.image {
    display: none;
}

.info {
    position: relative;
    border-radius: 4px;
}

.tag {
    border-radius: 0px 0px 4px 4px;
    right: var(--s-grid-padding);
    position: absolute;
    top: 0;
    z-index: 1;
}

.preprice {
    opacity: 0.6;
    text-decoration: line-through;
}

.price {
    strong {
        @include font-styles('h300');
        font-weight: map-get($font-weights, 'semi-bold');
    }
}

.postprice {
    // For tooltip
    --tooltip-width: 300px;
    a {
        border-bottom: 1px dashed $base-200;
        color: currentColor;
        position: relative;

        &:after,
        &:before {
            display: block;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 250ms ease-in;
        }

        &:after {
            @include font-styles('b200');
            background: $base-000;
            bottom: calc(100% + toRem(16));
            color: $base-1000;
            content: attr(data-title);
            left: toRem(-8);
            max-width: 40vh;
            overflow: hidden;
            padding: toRem(24);
            position: absolute;
            width: var(--tooltip-width);
            z-index: 100;
            pointer-events: none;
        }

        &:before {
            border-color: #000 transparent transparent transparent;
            border-style: solid;
            border-width: 9px 8px 0 8px;
            bottom: calc(100% + toRem(7));
            content: '';
            left: 0;
            position: absolute;
        }

        &:hover {
            &:after,
            &:before {
                visibility: visible;
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    strong {
        color: $secondary;
        font-weight: 400;
    }
}

.ctas {
    margin-top: auto;
    display: flex;
    align-self: flex-end;
    flex-direction: row-reverse;
}

@include media-breakpoint-up(md) {
    .card {
        flex-direction: row;
    }
    .image {
        display: block;
        height: auto;
        width: 50%;
    }

    .info {
        width: 50%;
    }
}
