@import '~base-imports';
$border-radius: 1.5rem;
$icon-list-size: toRem(28);

.imageContainer {
    border-radius: toRem(4);
    overflow: hidden;
}

.accordion {
    a {
        transition: $links-transition;
    }
}

.item {
    border-bottom: solid 1px $base-300;
    overflow: hidden;

    &:not(:last-of-type) {
        margin-bottom: toRem(8);
    }
}

.itemTitle {
    padding: var(--s-md) toRem(32) var(--s-md) 0;

    &::after {
        transition: transform ease-in-out 0.2s;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='%23111111'/%3E%3C/svg%3E%0A");
        background-size: contain;
        width: toRem(14);
    }
}

.open {
    .itemTitle {
        &::after {
            transform: scale(-1);
        }
    }
}

@include media-breakpoint-down(md) {
    .imageContainer {
        --aspect-ratio: 70%;
    }

    .titleDesktop {
        display: none;
    }

    .imageWrapper {
        margin-bottom: var(--s-md);
    }
}

@include media-breakpoint-up(lg) {
    .titleMobile {
        display: none;
    }

    .titleDesktop,
    .accordion {
        padding-right: var(--s-xs);
    }

    .imageContainer {
        position: sticky;
        top: var(--navbar-height);
    }
}

.featured {
    .item {
        border: 1px solid $primary-dark;
        border-radius: $border-radius;
    }

    .itemTitle {
        padding-left: toRem(20);

        &::after {
            right: toRem(20);
        }
    }

    .body {
        padding-left: 20px;
        padding-right: 20px;
    }

    .open {
        .itemTitle {
            background-color: rgba($primary-dark, 0.2);
        }
    }

    ul {
        list-style-type: none;
        padding: 0;

        li {
            margin-bottom: var(--s-xs);
            margin-left: toRem(36);
            min-height: $icon-list-size;
            position: relative;

            &:before {
                background-color: $primary-dark;
                content: '';
                display: block;
                height: $icon-list-size;
                left: toRem(-36);
                -webkit-mask-image: url('data:image/svg+xml; utf8, <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.3333 14.0001C27.3333 21.3639 21.3638 27.3334 14 27.3334C6.63616 27.3334 0.666626 21.3639 0.666626 14.0001C0.666626 6.63628 6.63616 0.666748 14 0.666748C21.3638 0.666748 27.3333 6.63628 27.3333 14.0001ZM21.5948 10.9573C22.1235 10.4447 22.1365 9.60054 21.6238 9.07189C21.1112 8.54324 20.2671 8.53025 19.7384 9.04288L12.4166 16.1428L9.59482 13.4065C9.06617 12.8939 8.22205 12.9069 7.70943 13.4355C7.1968 13.9642 7.20978 14.8083 7.73843 15.3209L11.4884 18.9573C12.0056 19.4588 12.8276 19.4588 13.3448 18.9573L21.5948 10.9573Z" fill="currentColor"/></svg>');
                mask-image: url('data:image/svg+xml; utf8, <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.3333 14.0001C27.3333 21.3639 21.3638 27.3334 14 27.3334C6.63616 27.3334 0.666626 21.3639 0.666626 14.0001C0.666626 6.63628 6.63616 0.666748 14 0.666748C21.3638 0.666748 27.3333 6.63628 27.3333 14.0001ZM21.5948 10.9573C22.1235 10.4447 22.1365 9.60054 21.6238 9.07189C21.1112 8.54324 20.2671 8.53025 19.7384 9.04288L12.4166 16.1428L9.59482 13.4065C9.06617 12.8939 8.22205 12.9069 7.70943 13.4355C7.1968 13.9642 7.20978 14.8083 7.73843 15.3209L11.4884 18.9573C12.0056 19.4588 12.8276 19.4588 13.3448 18.9573L21.5948 10.9573Z" fill="currentColor"/></svg>');
                position: absolute;
                top: 0;
                width: $icon-list-size;
            }
        }
    }
}
