@import '~base-imports';

.wrapper {
    @include media-breakpoint-down(md) {
        margin-left: calc(-1 * var(--s-grid-margin));
        margin-right: calc(-1 * var(--s-grid-margin));
    }
}

.content {
    padding-left: var(--s-grid-margin);
    padding-right: var(--s-grid-margin);

    @include media-breakpoint-up(lg) {
        border-radius: toRem(4);
    }

    :global(.checkout) {
        display: flex;
        align-items: flex-start;
        gap: toRem(24);

        > * {
            flex: 1 1 50%;
        }

        :global(.price) {
            border: solid 1px $base-300;
            border-radius: 4px;
            overflow: hidden;
        }
            
        :global(.table) {
            padding: toRem(20);
            @include font-styles(b100);

            p {
                display: flex;
                justify-content: space-between;
            }
        }

        :global(.prev) {
            text-decoration: line-through;
            opacity: 0.6;
            margin-bottom: toRem(4);
        }

        :global(.featured) {
            margin-bottom: toRem(16);
            font-weight: 600;
            @include font-styles(b400);
        }

        :global(.tag) {
            background-color: $secondary;
            color: $base-1000;
            text-align: center;
            padding: toRem(4);
            @include font-styles(b200);
        }

        :global(.tes) {
            display: none;
            
            @include media-breakpoint-up(xl) {
                display: flex;
                gap: toRem(8);
                background-color: $primary;
                border-radius: 4px;
                padding: toRem(24);
            }
        }

        :global(.item) {
            flex: 1 1 50%;
            background-color: $base-1000;
            border-radius: 4px;
            padding: toRem(24);
            display: flex;
            flex-direction: column;

            > p { 
                font-weight: 600;
                @include font-styles(b100);
            }
        }

        :global(.meta) {
            margin-top: auto;
            padding-top: toRem(8);
            @include font-styles(b100);

            p:first-child {
                font-weight: 600;
            }
        }

        hr {
            border: 0;
            border-top: solid 1px $base-300;
            margin: var(--s-lg) 0;
            outline: 0;
        }
    }
}
