@import '~base-imports';

.body {
    padding: 0;
}
.content {
    border-radius: 4px;
}
.button {
    width: 100%;
}

.buttonLink {
    padding: 0;
    text-decoration: underline;
}

.icon {
    cursor: pointer;
}

.code {
    cursor: pointer;
    &::placeholder {
        color: $base-000;
    }
}

.wrapperCopy {
    top: var(--s-xs);
    right: var(--s-sm);
    pointer-events: none;
}

.tooltip {
    background-color: $base-000;
    color: $base-1000;
    padding: var(--s-xxs) var(--s-md);
    margin-right: calc(var(--s-tiny) + toRem(4));
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent $base-000;
        top: var(--s-xxs);
        right: calc(var(--s-tiny) * -1);
    }
}

.label {
    margin: 0;
}


.loading {
    width: 32px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
