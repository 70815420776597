@import '~base-imports';

.imageContainer {
    position: relative;
    min-height: toRem(280);
    border-radius: toRem(4);
    overflow: hidden;

    &.imageNoRadius {
        border-radius: 0;
    }

    &.imageNoRadiusMobile {
        border-radius: 0;
    }
}
.paddingContainer,
.paddingContainerText {
    padding-top: var(--s-xl);
    padding-left: var(--grid-margin);
    padding-right: var(--grid-margin);
}
.paddingContainerText {
    padding-bottom: var(--s-xl);
}
.carousel,
.controls > div {
    padding: 0 !important;
}

.carousel {
    justify-content: flex-end;
}

.overflowLeft,
.overflowRight,
.contentLeft,
.contentRight {
    grid-column-start: 1;
    grid-column-end: -1;
}

.pretitle {
    em {
        @include font-styles('b200');
        color: rgba($base-000, 0.6);
        font-style: normal;
        font-weight: 400;
    }
}

@include media-breakpoint-down(sm) {
    .overflowLeft,
    .overflowRight {
        .carousel {
            margin-left: calc(var(--grid-margin) * -1);
            margin-right: calc(var(--grid-margin) * -1);
        }
    }

    .overflowLeft,
    .overflowRight,
    .contentLeft,
    .contentRight {
        padding-left: var(--grid-margin);
        padding-right: var(--grid-margin);
    }

    .ctas {
        text-align: center;
    }
}

@include media-breakpoint-up(md) {
    .imageContainer {
        min-height: toRem(413);
        position: sticky;
        top: var(--navbar-height);
        &.imageNoRadiusMobile {
            border-radius: toRem(4);
        }
    }

    .wrapper {
        padding-bottom: toRem(72);
    }

    .text {
        margin-top: var(--s-none);
    }

    .overflowLeft {
        grid-column-start: 1;
        grid-column-end: 4;
        margin-left: var(--grid-gutter-width-half-negative);
    }

    .contentRight {
        grid-column-start: 4;
        grid-column-end: -1;
    }

    .overflowRight {
        grid-column-start: 4;
        grid-column-end: -1;
        margin-right: var(--grid-gutter-width-half-negative);
    }

    .contentLeft {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .paddingContainer,
    .paddingContainerText {
        padding: 0;
    }
}

@include media-breakpoint-up(lg) {
    .overflowLeft {
        grid-column-end: 8;
    }

    .contentRight {
        grid-column-start: 8;
    }

    .overflowRight {
        grid-column-start: 8;
    }

    .contentLeft {
        grid-column-end: 8;
    }
}

@include media-breakpoint-up(xxl) {
    .imageContainer {
        min-height: toRem(570);
    }
}
