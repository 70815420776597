@import '~base-imports';

.dots {
    display: flex;
    padding-top: var(--s-md);
    justify-content: center;
    &:focus {
        outline: none;
    }
}

.dot {
    border: none;
    width: toRem(12);
    height: toRem(12);
    background: $primary-dark;
    opacity: 0.5;
    border-radius: 50%;
    margin: 0 toRem(4);
    cursor: pointer;
    @include media-breakpoint-up(md) {
        width: toRem(16);
        height: toRem(16);
    }
}

.active {
    opacity: 1;
}
