@import '~base-imports';

.cardTestimonial {
    border-radius: toRem(4);
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.testimonialInfo {
    flex: 1 1 auto;
}

.text {
    margin-bottom: toRem(4);
}

.imageContainer {
    height: toRem(280);
    width: 100%;
}

@include media-breakpoint-up(lg) {
    .cardTestimonial {
        flex-direction: row;
    }

    .title {
        font-size: toRem(30);
        line-height: toRem(40);
    }

    .imageContainer {
        height: 100%;
    }
}
