@import '~base-imports';

.wrapper {
    flex: 1 1 auto;

    * > section {
        padding-bottom: var(--s-xg);

        &:first-of-type {
            padding-top: var(--s-xxl);
        }

        &:last-of-type {
            padding-bottom: var(--s-xxl);
        }
    }
}
