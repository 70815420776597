@import '~base-imports';

.icon {
    color: #ffba08;
}

.underline {
    text-decoration: underline;
}

.image {
    max-width: toRem(100);
}
