@import '~base-imports';

.root {
    @each $breakpoint, $value in $navbar-offset {
        @if ($breakpoint == 'null') {
            --navbar-offset: #{toRem($value)};
        } @else {
            @include media-breakpoint-up($breakpoint) {
                --navbar-offset: #{toRem($value)};
            }
        }
    }
}

.root,
.collapse-content {
    border-bottom: 1px solid $base-350;
}

.btnIcon {
    &:last-of-type {
        margin-right: var(--s-tiny);
    }
}

.withExtraLogo {
    --logo-height: #{toRem(40)};
}

.extraLogo {
    max-height: var(--logo-height);
    max-width: toRem(120);
    position: absolute;
    left: calc(var(--navbar-offset) + var(--padding-x) + var(--s-xs));
}

@include media-breakpoint-up(md) {
    .extraLogo {
        right: unset;
        max-width: unset;
    }
}

@include media-breakpoint-up(lg) {
    .collapse {
        display: none !important;
    }

    .withExtraLogo {
        --logo-height: #{toRem(48)};
    }
}

@include media-breakpoint-up(xl) {
    .withExtraLogo {
        --logo-height: #{toRem(50)};
        --navbar-offset: #{toRem(195)};
    }
}
