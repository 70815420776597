@import '~base-imports';

.section {
    &[data-theme^='bg-'] {
        margin-top: var(--s-xxxl);
        padding-bottom: var(--s-xxxl);

        & + &[data-theme^='bg-'],
        &:first-child {
            margin-top: 0;
        }
    }

    &:last-of-type {
        padding-bottom: var(--s-xxxl);
    }

    &[data-container='full'],
    &[data-container='full-mobile'] {
        padding: 0;
        margin: 0;
        > div {
            margin: 0;
            padding: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &[data-container='full-mobile'] {
            margin-top: var(--s-xxxl);
            padding-bottom: var(--s-xxxl);

            & + &[data-theme^='bg-'],
            &:first-child {
                margin-top: var(--s-xxxl);
                &.marginOnlyText {
                    padding-bottom: var(--s-xl);
                    padding-top: var(--s-xl);
                    margin-top: 0;
                }
            }

            > div {
                max-width: var(--container-max-width);
                padding-left: var(--grid-margin);
                padding-right: var(--grid-margin);
                margin-right: auto;
                margin-left: auto;
            }
        }
    }
}

.anchor {
    top: toRem(-80);
}
