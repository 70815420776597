@import '~base-imports';

.wrapper {
    gap: 1px;
    border: 1px solid $base-300;

    @include media-breakpoint-up(lg) {
        &.two {
            > * {
                grid-column: span 6;
            }
        }

        &.three {
            > * {
                grid-column: span 4;
            }
        }
    }
}
