@import '~base-imports';

.itemsContainer {
    display: grid;
    row-gap: var(--s-md);
}

@include media-breakpoint-up(lg) {
    .itemsContainer {
        grid-template-columns: 2fr 3fr 2fr;
        column-gap: toRem(48);
    }
}
