@import '~base-imports';

.content {
    height: 100vh;
}

.body {
    align-items: center;
    display: flex;
    justify-content: center;
}

.closeButton {
    $image: 'data:image/svg+xml; utf8, <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path d="M8.457 8.457c0.521-0.521 1.365-0.521 1.886 0l13.199 13.199c0.521 0.521 0.521 1.365 0 1.886s-1.365 0.521-1.886 0l-13.199-13.199c-0.521-0.521-0.521-1.365 0-1.886z" fill="currentColor"></path><path d="M8.457 23.542c-0.521-0.521-0.521-1.365 0-1.886l13.199-13.199c0.521-0.521 1.365-0.521 1.886 0s0.521 1.365 0 1.886l-13.199 13.199c-0.521 0.521-1.365 0.521-1.886 0z" fill="currentColor"></path></svg>';
    -webkit-appearance: none;
    -webkit-mask-image: url($image);
    -webkit-mask-size: toRem(40);
    background: no-repeat center center currentColor;
    mask-size: toRem(40);
    border: 0 none;
    height: toRem(40);
    left: 50%;
    mask-image: url($image);
    padding: 0;
    position: absolute;
    top: toRem(20);
    transform: translateX(-50%);
    width: toRem(40);
    z-index: 10;

    &:hover {
        background-color: $primary-darker;
    }

    > span {
        display: none;
    }
}

.video {
    margin-top: toRem(24);
    max-height: calc(100vh - (#{toRem(64)} * 2) - #{toRem(24)});
    width: 100%;
}

@include media-breakpoint-up(lg) {
    .body {
        padding: toRem(64);
    }
}
