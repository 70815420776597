@import '~base-imports';

.wrapper {
    margin-top: 0 !important;
    padding-top: 0;
    padding-bottom: var(--s-xl) !important;
    background-color: $base-400;
}

.points {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: toRem(24);
}

@include media-breakpoint-up(lg) {
    .wrapper {
        background: none;
    }
}

@include media-breakpoint-up(xl) {
    .points {
        column-gap: toRem(72);
    }
}

@include media-breakpoint-up(xxl) {
    .points {
        column-gap: toRem(144);
    }
}

.wrapperReview {
    background-color: $base-1000;
}
