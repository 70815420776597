@import '~base-imports';

.body {
    padding: var(--s-xg) var(--s-grid-margin);

    .icon {
        path {
            fill: $primary-dark;
        }
    }

    .error {
        background-color: $support-error;
        border-radius: 50%;

        path {
            fill: #fff;
        }
    }

    .loading {
        animation: spin 2s linear infinite;
    }

    .buttons {
        a,
        button {
            width: 100%;
        }
    }

    .closeButton {
        text-decoration: underline;
        text-underline-offset: 6px;
    }
}

@include media-breakpoint-up(md) {
    .title {
        text-align: left;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
