@import '~base-imports';

$skeleton-radius: toRem(4);
$skeleton-background: $base-400;
$skeleton-bg-animation: $base-300;
$skeleton-delay: 1.25s;

@keyframes loading {
    100% {
        transform: translateX(-100%);
    }
}

[data-loading='true'] {
    [data-skeleton='true'] {
        position: relative;
        overflow: hidden;

        padding: 0.25rem;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            overflow: hidden;
        }

        > [data-picture-wrapper='true'] {
            opacity: 0;
        }

        &::before {
            background: $skeleton-background;
        }

        &::after {
            transform: translateX(100%);
            background: linear-gradient(
                90deg,
                transparent,
                $skeleton-bg-animation 25%,
                transparent 100%
            );
            animation: loading $skeleton-delay infinite;
        }
    }
}

[data-loading='false'] {
    [data-skeleton='true'] {
        &::before,
        &::after {
            display: none;
        }

        > [data-picture-wrapper='true'] {
            opacity: 1;
        }
    }
}
