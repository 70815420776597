@import '~base-imports';

.card {
    border-radius: 4px;
    overflow: hidden;
    --image-height: #{toRem(116)};
    --image-width: 100%;

    .image {
        height: var(--image-height);
        width: var(--image-width);
    }
}

@include media-breakpoint-up(lg) {
    .card {
        --image-height: auto;
        --image-width: 50%;
        flex-direction: row;

        .info {
            width: 50%;
        }
    }
}
