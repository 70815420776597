@import '~base-imports';

.button {
    white-space: nowrap;

    &.buttonIcon,
    &.buttonIconLight {
        .icon {
            border: 3px solid currentColor;
            border-radius: 50px;
            box-sizing: content-box;
        }

        &:hover,
        &:focus {
            .icon {
                background-color: currentColor;
            }
        }
    }

    &.buttonIcon {
        &:hover,
        &:focus {
            .icon {
                path {
                    fill: $base-1000 !important;
                }
            }
        }
    }

    &.buttonIconLight {
        &:hover,
        &:focus {
            .icon {
                path {
                    fill: $base-000 !important;
                }
            }
        }
    }

    &.buttonIconBorder {
        white-space: normal;
        word-break: break-all;
        text-align: left;
        align-items: flex-start;

        .icon {
            flex: 0 0 auto;
        }

        span {
            text-decoration: underline;
            text-decoration-thickness: toRem(2);
            text-underline-offset: toRem(6);
        }

        &:hover,
        &:focus {
            .icon {
                path {
                    fill: $primary-darker !important;
                }
            }
            span {
                text-decoration-color: $primary-darker;
            }
        }
    }

    &.link {
        text-decoration: underline;
        text-decoration-thickness: toRem(2);
        text-underline-offset: toRem(6);
    }

    &.right {
        padding: 0;
        width: 100%;
        justify-content: flex-end;
    }
}

@include media-breakpoint-up(lg) {
    .button {
        &.primarySmall,
        &.secondarySmall {
            .icon {
                height: auto;
                width: toRem(20);
            }
        }
    }
}

@each $type, $values in $button-custom {
    .btn-#{$type} {
        @include btn-type($type);
    }
}
