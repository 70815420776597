@import '~base-imports';

.wrapper {
    &.large,
    &.medium {
        --card-width: #{toRem(290)};
    }

    &.small {
        --card-width: #{toRem(232)};
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        &.large {
            --card-width: #{toRem(895)};
        }

        &.medium {
            --card-width: #{toRem(411)};
        }

        &.small {
            --card-width: #{toRem(263)};
        }
    }
}

@include media-breakpoint-up(xxl) {
    .wrapper {
        &.large {
            --card-width: #{toRem(1085)};
        }
    }
}
