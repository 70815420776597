@import '~base-imports';

.wrapper {
    background-color: var(--special-bg-color, $theme-bg-primary--bg);
    color: var(--special-text-color, $theme-bg-primary--text);
}

.button {
    background-color: var(--special-button-bg-color, $base-1000);
    color: var(--special-button-text-color, $primary-darker);

    svg path {
        fill: var(--special-button-text-color, $primary-darker) !important;
    }

    &:hover {
        background-color: var(--special-button-hover-bg-color, $primary-darker);
        color: var(--special-button-hover-text-color, $base-1000);

        svg path {
            fill: var(--special-button-hover-text-color, $base-1000) !important;
        }
    }
}

.text {
    @include media-breakpoint-up(lg) {
        max-width: toRem(530);
        margin-right: toRem(12);
    }

    a {
        color: currentColor;
    }
}
