@import '~base-imports';
@import '~bootstrap/scss/tables';

.htmlSection {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: map-get($font-weights, 'semi-bold');
    }

    a {
        @include linksStyles;
    }

    table {
        th,
        td {
            padding: toRem(8);
        }
    }
}
