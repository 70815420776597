@import '~base-imports';

@include media-breakpoint-up(lg) {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .wrapper {
        --link-color: #{$var-text-color};
        --link-hover-color: #{$theme-color--menu-link};

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 240px;

        > * {
            flex: 0 0 50%;
            padding-right: var(--s-lg);
            margin-bottom: var(--s-sm);
        }
    }

    .link {
        display: flex;
        align-items: center;

        svg {
            margin-left: toRem(12);
            opacity: 0;
            transition: $links-transition;
        }

        &.active,
        &:hover {
            svg {
                opacity: 1;
            }
        }

        &.active {
            color: var(--link-hover-color);
        }
    }
}
