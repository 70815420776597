@import '~base-imports';

.cta {
    text-align: center;
}

.mb {
    margin-bottom: toRem(16);
    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.mt {
    margin-top: toRem(24);
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}
