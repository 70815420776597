@import '~base-imports';

.wrapper {
    position: relative;

    &:hover {
        .layer {
            opacity: 1;
        }
    }
}

.layer {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    img {
        max-height: toRem(64);
    }
}

.icon {
    path {
        fill: $primary-darker !important;
    }
}
