@import '~base-imports';

div {
    .plan {
        transition: background-color 0.2s linear;

        &:hover {
            background-color: $primary-darker !important;
            color: $base-1000 !important;
        }
    }
}

.itemsWrapper {
    border: 1px solid $base-300;
    border-width: 1px 1px 0 1px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: center;
}

@include media-breakpoint-up(lg) {
    .itemsWrapper {
        border: 0;
        grid-template-columns: toRem(260) 1fr 1fr 1fr;
        margin-top: var(--s-xg);
    }
}

@include media-breakpoint-up(xxl) {
    .itemsWrapper {
        grid-template-columns: repeat(4, 1fr);
    }
}
