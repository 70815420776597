@import '~base-imports';

.line {
    height: 1px;
    border: none;
}

@include media-breakpoint-down(md) {
    .fullWidth {
        margin-left: calc(var(--grid-margin) * -1);
        margin-right: calc(var(--grid-margin) * -1);
    }
}
