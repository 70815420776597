@import '~base-imports';

.picture {
    --aspect-ratio: 34.8%;
}

.wrapper {
    height: 100%;
    width: 100%;
}

.card {
    border: 2px solid $base-300;
    height: 100%;
}

.recommended {
    border-radius: 0px 0px 4px 4px;
    left: toRem(20);
    position: absolute;
    top: 0;
}

.resume {
    border-bottom: 1px solid $base-300;
    width: 100%;
}

.description {
    margin-top: toRem(8);
    opacity: 0.5;
}

.button {
    margin-top: auto;
    align-self: flex-end;
}

.ctas {
    margin-top: auto;
    display: flex;
    align-self: flex-end;
    flex-direction: row-reverse;
}

@include media-breakpoint-up(lg) {
    .picture {
        --aspect-ratio: 30%;
    }

    .recommended {
        left: toRem(32);
    }

    .resume {
        display: grid;
        grid-template-areas: 'title price';
        align-items: baseline;

        &.withDescription {
            grid-template-areas:
                'title price'
                'description price';
        }
    }

    .title {
        grid-area: title;
    }

    .description {
        grid-area: description;
        margin-top: 0;
    }

    .price {
        grid-area: price;
        margin-left: toRem(12);

        strong {
            @include font-styles('h300');
            font-weight: map-get($font-weights, 'semi-bold');
        }
    }
}

@include media-breakpoint-up(xxl) {
    .picture {
        --aspect-ratio: 26%;
    }

    .recommended {
        left: toRem(64);
    }
}

.ctaCenter {
    align-self: center;
}

.wrapperItem {
    @include media-breakpoint-up(md) {
        align-items: center;
    }
}

.text {
    padding-top: toRem(4);
    @include media-breakpoint-up(md) {
        padding-top: 0;
    }
}

.wrapperMobile {
    width: 100%;
    margin-top: var(--s-md);
    padding-top: var(--s-xg);
    border-top: 1px solid $base-300;
}

.resumeFeatureList {
    width: 100%;

    @include media-breakpoint-up(md) {
        border-bottom: 1px solid $base-300;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
        margin-bottom: 0;
        &.resume {
            border-bottom: 0;
        }
    }
}

.yes {
    color: $primary-dark;
}

.no {
    color: $base-200;
}

.icon {
    flex-shrink: 0;
}
