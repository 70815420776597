@import '~base-imports';

.btn {
    top: toRem(3);
    right: toRem(3);
}

.input {
    padding-right: toRem(126);

    &::placeholder {
        transition: color 0.15s ease-in-out;
    }

    &:focus {
        &::placeholder {
            color: $input-color;
        }
    }
}

.inputDefaultBorder {
    border-color: $input-border-color !important;
}

.inputValidator {
    padding-left: $input-padding-x-sm;
    margin-top: toRem(-7);
}

.inputValidatorLoading {
    color: $base-200;
}

@include media-breakpoint-up(lg) {
    .inputValidator {
        margin-top: toRem(4);
    }
}
