@import '~base-imports';

.controls {
    color: currentColor;
    width: 100%;
}

.button {
    background-color: transparent;
    border-radius: 50px;
    border: 2px solid var(--text-color);
    line-height: 0;
    transition: all 0.2s ease;

    .icon {
        flex: 0 0 auto;
        color: var(--text-color);
    }

    &.large {
        height: toRem(48);
        width: toRem(48);
    }

    &.small {
        height: toRem(40);
        width: toRem(40);
    }

    &:hover:not(:disabled) {
        background-color: $primary-darker;

        .icon {
            color: $base-1000;
        }
    }

    &:disabled {
        border-color: $base-200;

        .icon {
            color: $base-200;
        }
    }
}
