@import '~base-imports';

.wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

.alert {
    border: 0;
    margin-bottom: 0;
    padding: toRem(10);
    text-align: center;
}

.linkUnderline {
    a {
        font-weight: map-get($font-weights, 'semi-bold');
        color: currentColor;
        border-color: currentColor;
    }
}
