@import '~variables';

// hack for more specifity
.active.active.active {
    color: $var-link-color !important;

    svg path {
        fill: $var-link-color !important;
    }
}

.underline {
    text-decoration: underline;
}
