@import '~base-imports';

$border-radius: 4px;

.picture {
    --aspect-ratio: 34.62%;
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;

    img {
        object-position: top;
    }
}

.resume {
    border-radius: 0 0 $border-radius $border-radius;
    overflow: hidden;
}

@include media-breakpoint-up(lg) {
    .picture {
        --aspect-ratio: 75.58%;
        border-radius: 0 $border-radius $border-radius 0;
        flex-shrink: 0;
        margin-right: var(--s-md);
        order: 2;
        width: toRem(213);

        img {
            object-position: initial;
        }
    }

    .resume {
        border-radius: $border-radius 0 0 $border-radius;
        flex-basis: toRem(342);
        flex-shrink: 0;
        margin-bottom: 0;
        order: 1;
    }

    .text {
        order: 3;
    }
}

@include media-breakpoint-up(xxl) {
    .resume {
        width: 25%;
    }
}
