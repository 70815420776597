@import '~base-imports';

.picture {
    --aspect-ratio: 40%;
}

.wrapper {
    height: 100%;
    width: 100%;
}

.ctaWrapper {
    align-self: flex-end;
}

@include media-breakpoint-up(lg) {
    .picture {
        --aspect-ratio: 42.4%;
    }
}

@include media-breakpoint-up(xxl) {
    .picture {
        --aspect-ratio: 40.3%;
    }
}
