@import '~base-imports';

.card {
    --height: #{toRem(362)};
    height: var(--height);
    overflow: hidden;
}

.content {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 70%) 100%
    );
    min-height: 50%;
}

@include media-breakpoint-down(md) {
    .card {
        > div[data-picture-wrapper] {
            transform: scale(1.25);
            transform-origin: center center;
        }
    }
}

@include media-breakpoint-up(lg) {
    .card {
        --height: #{toRem(444)};
    }
}

@include media-breakpoint-up(xl) {
    .card {
        --height: #{toRem(488)};
    }
}
