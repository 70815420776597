@import '~base-imports';

.wrapper {
    display: grid;
    gap: var(--s-xs);
}

.link {
    .icon,
    &:hover .icon {
        color: $primary-dark,
    }

    &:hover {
        .text {
            border-color: $base-000;
        }
    }
}

.icon {
    flex: 0 0 auto;
}

.text {
    border-bottom: 1px dashed $base-200;
}

@include media-breakpoint-up(md) {
    .wrapper {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include media-breakpoint-up(lg) {
    .wrapper {
        grid-template-columns: repeat(3, 1fr);
    }
}
