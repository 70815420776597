@import '~base-imports';

.text {
    b:first-of-type {
        color: $primary;
    }
    margin-right: toRem(12);
}

.wrapperButton {
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.button {
    align-self: flex-end;
}
