@import '~base-imports';

.wrapper {
    gap: var(--s-md);
    
    > * {
        flex: 1 0 0;
    }
    
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@include media-breakpoint-down(md) {
    .wrapper {
        margin-left: $grid-margin * -1;
        margin-right: $grid-margin * -1;
        padding-left: $grid-margin;
        padding-right: $grid-margin;

        > * {
            flex-basis: toRem(150);
        }
    }
}
