@import '~base-imports';

.footer {
    a {
        @include linksStyles;
    }

    ul {
        li {
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &[data-theme='bg-gray'] {
        --link-color: #{$base-000};
    }
}

.text {
    p:not(:last-of-type) {
        margin-bottom: var(--s-xs);
    }

    a {
        border-color: currentColor;
    }
}

.colWrapper {
    &:after {
        background-color: $base-300;
        content: '';
        display: block;
        height: 1px;
        inset: 0 var(--grid-gutter-width-half);
        position: absolute;
    }

    &:after {
        bottom: 0;
        top: auto;
    }
}

@include media-breakpoint-up(lg) {
    .logo {
        --icon-size: #{toRem(202)};
        width: var(--icon-size);
    }

    .colWrapper {
        margin-bottom: 0;
        margin-top: 0;
        padding-top: 0;

        &:before,
        &:after {
            display: none;
        }
    }

    .social {
        margin-bottom: 0;
    }

    .copyright,
    .social {
        margin-top: var(--s-xs);
    }

    .nav {
        display: flex;
        gap: var(--grid-gutter-width);
        > * {
            flex: 1 1 auto;
        }
    }
}
